import {useEffect, useRef, useState} from 'react';
import {cn} from '~/lib/utils';

export function FadeInSection({
  children,
  className,
}: {
  children: JSX.Element | JSX.Element[];
  className?: string;
}) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting === false) return;
        setVisible(entry.isIntersecting);
      });
    });
    observer.observe(domRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div
      className={cn(
        `invisible ${isVisible ? '!visible fadeUp' : ''}`,
        className,
      )}
      ref={domRef}
    >
      {children}
    </div>
  );
}
